import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Container, Button, Card, CardContent, Typography, Paper, Grid, CardMedia, CardActionArea } from '@mui/material';
import AppBarComponent from '../../components/ui/AppBarComponent';
import Footer from '../../components/ui/Footer';
// import SlackImg1 from './components/media/logos/slack.svg.png';
import slack01 from '../../components/media/logos/slack_01.jpg';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FlareIcon from '@mui/icons-material/Flare';
import slack02 from '../../components/media/logos/slack_02.jpg';
import DiscordImg1 from '../../components/media/logos/discord1.png';
import integrationImg from '../../components/media/storyset/svgs/operating-system.svg';
import slackIcon from '../../components/media/flats/icons/slack.svg';
import discordIcon from '../../components/media/flats/icons/discord.svg';
import integrations_workspaces from '../../components/media/illustrations/integrations_workspace.svg'
import evokeSlackDemo from '../../components/media/evoke-slack-demo2.gif';
import SlackGif from '../../components/media/gifs/Slack_evoke_small_008-ezgif.com-optimize.gif';
import DiscordGif  from '../../components/media/gifs/Discord_mini_001-ezgif.com-optimize.gif';

const slackInstallButton = 'https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,im:history,links:read,chat:write,files:read,files:write,im:read,mpim:history,channels:join,channels:history,groups:history,chat:write.customize&user_scope='
const platforms = {
  slack: {
    name: 'Slack',
    index: 0,
    bgColor: '#fff7fd', // Slack color #611F69
    topIcon: slackIcon,
    logo: slack01,
    installButton: slackInstallButton,
    link: 'https://slack.com',
    // demo: evokeSlackDemo,
    demo: SlackGif,
    about: `Slack is a messaging app for business that connects people to the information they need.`
  },
  discord: {
    name: 'Discord',
    index: 1,
    bgColor: '#f2f3fe', // Discord color #404EED
    logo: DiscordImg1,
    demo: DiscordGif,
    topIcon: discordIcon,
    installButton: 'https://discord.com/oauth2/authorize?client_id=1121772755659014298',
    link: 'https://discord.com',
    about: `Discord is a voice, video and text communication service used by over a hundred million people to hang out and talk with their friends and communities.`
  }
}

const featureList = platform => [
  { text: 'Integrate seamlessly with ' + platform.name },
  { text: 'Analyze data by easily tagging bot in a message' },
  { text: 'Get secure link to view result' },
  { text: 'Enjoy awesome interactive info-graphics' },
  { text: 'Generate reports using artificial intelligence (AI) like chatGPT' }
]


const SlackStyle2 = { height: '20px', width: '20px', marginRight: '12px' };
const SlackStyle = { alignItems: 'center', color: '#fff', backgroundColor: '#4A154B', borderRadius: '4px', display: 'inline-flex', fontFamily: 'Lato, sans-serif', fontSize: '16px', fontWeight: 600, height: '48px', justifyContent: 'center', textDecoration: 'none', width: '256px' }
// const cc = ()=><Box style={{ alignItems: 'center', color: '#fff', backgroundColor: '#4A154B', borderRadius: '4px', display: 'inline-flex', fontFamily: 'Lato, sans-serif', fontSize: '16px', fontWeight: 600, height: '48px', justifyContent: 'center', textDecoration: 'none', width: '256px' }}></Box>
let slackInstallLink = `https://slack.com/oauth/v2/authorize?`
const resp_scopes = 'scope=app_mentions:read,channels:join,chat:write,files:read,im:history,links:read,users:read,users:read.email&user_scope=email,openid,profile&state=install';
const client_id = `client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}`;
// const redirect_uri = `redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URL}`;
slackInstallLink+=`${client_id}&${resp_scopes}`;

const SlackAppLoginButton = ()=><Box>
  <div><a href={slackInstallLink} style={SlackStyle} ><svg xmlns="http://www.w3.org/2000/svg" style={SlackStyle2} viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>Sign in with Slack</a></div>
</Box>

// const slackUrl = 'https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,channels:join,chat:write,files:read,im:history,links:read,users:read,users:read.email&user_scope=email,openid,profile';
const slackUrl = 'https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,channels:join,chat:write,files:read,im:history,links:read,users:read,users:read.email&user_scope=email,openid,profile';
const SlackButton_1 = ({textAlign}) => <Box textAlign={textAlign || ''}><a href={slackUrl}><img alt="Add to Slack" height="40" width="179" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a></Box>
const SlackButton = ({textAlign}) => <Box textAlign={textAlign || ''}><a href={slackInstallLink} style={SlackStyle} ><svg xmlns="http://www.w3.org/2000/svg" style={SlackStyle2} viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>Add to Slack</a></Box>


const TopSection = ({ platform }) => (
  <Box sx={{backgroundImage: integrations_workspaces }}>
    <Box sx={{mt: 8, backgroundImage: integrations_workspaces }}>
    <Grid container spacing={2} sx={{ height: 600, backgroundColor: '' }}>
    <Grid item xs={1}></Grid>
      <Grid item xs={6}>
        <Box sx={{ mt: 10 }}>
        <Typography sx={{ fontWeight: 500, mt: 3, color: '#000', lineHeight: 1.2 }} variant='h3' color="text.secondary">
          Simplify data analytics <br />for your team with <br />Evoke Insight app<br />for {platform.name}
        </Typography>
        </Box>
        <Box sx={{my: 3}}>
          {platform.index%2 == 0 && platform.name !== 'Slack' && <Button variant='outlined' href={platform.installButton}>Add Bot to your {platform.name} </Button>}
          { platform.name === 'Slack' &&  <SlackButton textAlign={''} />}
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Container>
          <img src={integrations_workspaces} />
        </Container>
      </Grid>
    </Grid>
    </Box>
</Box>
)

const TextContainer = ({data}) =>(
  <Container sx={{my: 4}}>
    <Box>
      <Typography variant='h5' sx={{ fontWeight: 800 }}>{data.name}</Typography>
    </Box>
    <Box sx={{lineHeight: 1.5, mt: 2 }}>
      <Typography>{data.about}</Typography>
    </Box>
  </Container>
);

const ImageBox = ({data, height = 480}) =>(
  <Box>
  <Card>
    <CardActionArea href={data.link}>
    <CardMedia
      component="img"
      alt={data.name}
      height={height}
      width={800}
      image={data.demo}
    />
    </CardActionArea>
  </Card>
</Box>
)

const PlatformsSection = ({ platform }) =>{
  return (
    <Box sx={{ px: 2, py: 8, backgroundColor: platform.bgColor, height: 500 }}>
        <Container sx={{ pt: 4, mt: 6 }}>

          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
            { platform.index%2 == 1 && <TextContainer data={platform} /> }
              { platform.index%2 == 0 && <ImageBox data={platform} /> }

              {platform.index%2 == 1 && <Button variant='outlined' href={platform.installButton}> Add Bot to your {platform.name} </Button>}
            </Grid>
            {/* <Grid item xs={1}></Grid> */}
            <Grid item xs={8}>
              { platform.index%2 == 0 && <TextContainer data={platform} />}
              { platform.index%2 == 1 && <ImageBox data={platform} />}
              <Box sx={{px: 4}}>
              {platform.index%2 == 0 && platform.name !== 'Slack' && <Button variant='outlined' href={platform.installButton}>Add Bot to your {platform.name} </Button>}
              { platform.name === 'Slack' &&  <SlackButton />}
              </Box>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Container>
  </Box>
  )
  };

  const GetStarted = ({platform}) => (
    <Box sx={{minHeight: 500}}>
      <Box sx={{my: 1}}>
        <Box sx={{height: 100}}></Box>
        <Container sx={{my: 8}}>

        <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Box>
                <Typography variant='h2' align='center' sx={{ fontWeight: 200 }}>Get Started <br /> now</Typography>
              </Box>
              <Box sx={{px: 4, mt: 10, alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
                {platform.index%2 != 0 && platform.name !== 'Slack' && <Box textAlign='center'><Button variant='outlined' href={platform.installButton}>Add Bot to your {platform.name}</Button></Box>}
                { platform.name === 'Slack' &&  <SlackButton textAlign='center' />}
              </Box>

            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
        </Container>
      </Box>
    </Box>
  )

  const FeatureSection = ({ platform }) =>{
    return (
      <Box sx={{ mt: 10, py: 2, backgroundColor: platform.bgColor, minHeight: 650 }}>
        <Box>
        <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Box sx={{mt: 10}}>
            <Typography align="center" sx={{ fontWeight: 300, mt: 3, color: '#000', lineHeight: 1.5 }} variant='h3' color="text.secondary">
              Features that <br />speaks convenience! 
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}></Grid>
        </Grid>
        </Box>
          <Container sx={{ pt: 4, mt: 12 }}>
  
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{mt: 10}}>
                  {platform && platform.name && (
                    featureList(platform).map((f, i) =>(
                      <Box sx={{py: 2}} key={i}>
                        <Typography sx={{fontSize: '14px'}}><FlareIcon /> {f.text}</Typography>
                      </Box>
                    ))
                  )}

                </Box>
              </Grid>
              {/* <Grid item xs={1}></Grid> */}
              <Grid item xs={8}>
                <Box sx={{mt: 1}}>
                  <ImageBox data={platform} height={600} />
                </Box>
              </Grid>
              {/* <Grid item xs={1}></Grid> */}
            </Grid>
          </Container>
    </Box>
    )
    };
  


const IntegrationPage = () => {

  const [ focus, setFocus ] = useState(null);
  const params = useParams()
  const [searchParams] = useSearchParams();
  const accessType = searchParams.get('at');
  const { app } = params;
  const integrationList = ['slack', 'discord']

  const handleFocus = () => {
    if (!app || !integrationList.includes(app.toLowerCase())) return setFocus(404);
    setFocus(app.toLowerCase());
  }

  useEffect(()=>{
    handleFocus();
  },[app]);

  
  return (
    <Box sx={{ minWidth: 275 }}>
      <AppBarComponent />
      <Box>
        {
          focus && focus!== 404 && (<Box>
            <TopSection platform={platforms[focus]} />
            {/* <PlatformsSection platform={platforms[focus]} /> */}
            <FeatureSection platform={platforms[focus]} />
            <GetStarted platform={platforms[focus]} />
          </Box>)
        }
      </Box>
      <Footer />
    </Box>
  );
}

// export default Integrations;

export default IntegrationPage;